@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file+'.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
}

@include font('Roboto', './fonts/Roboto-Regular');
@include font('Roboto Condensed', './fonts/RobotoCondensed-Regular');

body {
  font-family: 'Roboto', sans-serif;
}
h1,h2,h3,h4,h5 {
  padding: 20px 0;
  color: #962033!important;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 35px;
}
h2 {
  font-size: 30px;
  text-align: center;
  color: #000!important;
  padding: 0 0 20px 0;
}
h3 {
  font-weight: 900;
  font-size: 30px;
}
h4 {
  font-weight: 700;
  font-size: 24px;
}
form {
  margin: 20px 0;
}
.intro-text {
  font-size:13px;
  text-align:left;
  ul {
    padding-left: 1.2em;
  }
}
.section-title {
  text-align: center;
  padding: 20px 0;
}
.language-block {
  background: #ededed;
  color: #555;
  padding:30px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  cursor:pointer;
}
.btn-primary {
  background: #014c67;
  border:1px solid #fff;
  border:none;
  &:hover {
    color:#ff5f00;
    background: #014c67;
    border:1px solid #fff;
  }
}
.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(206,206,206,1);
  -moz-box-shadow: 0px 5px 5px 0px rgba(206,206,206,1);
  box-shadow: 0px 5px 5px 0px rgba(206,206,206,1);
  padding: 10px;
  margin: 0 auto;
  text-align: center;
  // height: 60px;
}

.header-center {
  margin-left: auto;
  text-align: center;
  font-weight: bold;
    height: 60px;

}

.header-right {
  margin-left: auto;
  text-align: right;
  font-weight: bold;
  height: 60px;
}

.header-logo_image {
  // width: 250px;
  text-align: center;
  height: 60px;
}
.div-spinner {
  width:50%;
  margin:40vh auto;
  text-align:center;
}

.img-choise {
	margin:10px; border:2px solid #fff;
  box-shadow: 2px 2px 5px #ccc;
  -moz-box-shadow:  2px 2px 5px #ccc;
  -webkit-box-shadow:  2px 2px 5px #ccc;
  -khtml-box-shadow:  2px 2px 5px #ccc;
}

.footer-controls {
  margin: 30px 0;
  text-align: center;
}

.spinner-container {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  div {
    color: #962033;
    width: 5rem;
    height: 5rem;
  }
}

.options-container {
  padding: 0 40px;
}

.privacy-check {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.react-video-controls {
  display: none !important;
}

.datepicker{
    max-width: 100% !important;
    width: 100% !important;
    border-radius: .25rem;
    border: 1px solid #ccc !important;
    outline: none;
    height: calc(1.5em + .75rem + 2px);
}

.react-datepicker-wrapper {
    width: 100%;
}

.pagination {
    width: 100%;
    padding: 20px;
    display: inline-flex;
    justify-content: center;
}

.pagination-button {
    color: rgb(150, 32, 51);
    border: 2px solid rgb(150, 32, 51);
    background-color: #fff;
    margin-right: 5px;
    min-width: 25px;
    text-align: center;
    &:hover {
        // color: rgb(150, 32, 51);
        border: 2px solid rgb(150, 32, 51);
        background-color: #fff;
    }
}

.pagination-button-next {
    color: rgb(150, 32, 51);
    border: 2px solid rgb(150, 32, 51);
    background-color: #fff;
    margin-right: 5px;
    padding: 3px;
    text-align: center;
    &:hover {
        // color: rgb(150, 32, 51);
        border: 2px solid rgb(150, 32, 51);
        background-color: #fff;
    }
}

.pagination-button-active {
    color: #fff;
    border: 2px solid rgb(150, 32, 51);
    background-color: rgb(150, 32, 51);
    margin-right: 5px;
    min-width: 25px;
    text-align: center;
    &:hover {
        color: #fff;
        border: 2px solid rgb(150, 32, 51);
        background-color: rgb(150, 32, 51);
    }
}

.pagination-button {
    color: #fff;
    border: 2px solid rgb(150, 32, 51);
    background-color: rgb(150, 32, 51);
    margin-right: 5px;
    min-width: 25px;
    text-align: center;
    &:hover {
        color: #fff;
        border: 2px solid rgb(150, 32, 51);
        background-color: rgb(150, 32, 51);
    }
}

.pagination-button-link {
  display: block;
  color: inherit;
  width: 25px;
  &:hover {
    color: inherit;
  }
  &:focus {
    outline: none;
  }
}

.table td, .table th {
    padding: 10px;
}

.privacy-text-error {
    color: red !important;
}

.privacy-check-error {
    color: red !important;
}

.form-control-error {
    border-color: #dd7c85 !important;
    outline: 0 !important;
    color: #495057;
    background-color: #fff;
}

.form-control-error:focus {
    box-shadow: 0 0 0 0.2rem rgba(220,53,69,.25) !important;
}

.iframe-captivate {
  flex:1;
  height: 100%;
}
